import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { getSearchParams } from 'gatsby-query-params'

import LanguageTabs, { useLang } from '../components/LanguageTabs'
import welcome_EN from '../content/landingPages/welcome_EN'
import welcome_ES from '../content/landingPages/welcome_ES'
import iconBottle from '../images/list-icons/icon-bottle.svg'
import iconMessage from '../images/list-icons/icon-message.svg'
import iconSupport from '../images/list-icons/icon-support.svg'
import iconSafe from '../images/list-icons/icon-safe.svg'
import CallToActionLink from '../components/CallToActionLink'

const content = {
  EN: welcome_EN,
  ES: welcome_ES,
}

const WelcomeLandingPage = () => {
  const queryParams = getSearchParams()
  const { sp } = queryParams
  const lang = useLang(content)

  const getFAQUrl = () => {
    const paramsStr = new URLSearchParams(queryParams).toString()
    return paramsStr ? `/faqs-bottle?${paramsStr}` : '/faqs-bottle'
  }

  return (
    <main className="welcome-landing-page">
      <div className="welcome-hero">
        <div className="welcome-hero-content">
          <div className="welcome-hero-text">{lang('Welcome').heading}</div>
          <StaticImage src="../images/logo-white.png" alt="Aidia System" placeholder="none" layout="fixed" />
        </div>
      </div>

      <div className="landing-page-section">
        <LanguageTabs />
        <div className="intro">
          <h2>{lang('A simple system').heading}</h2>
          <p>{sp === 'onco' ? lang('A simple system_onco').content : lang('A simple system').content}</p>
        </div>

        <div className="how-it-works">
          <div className="how-it-works-item">
            <img src={iconBottle} alt="bottle" />
            <div className="how-it-works-text">
              <h3>{lang('Helpful reminders').heading}</h3>
              <p>{lang('Helpful reminders').content}</p>
            </div>
          </div>
          <div className="how-it-works-item">
            <img src={iconMessage} alt="text" />
            <div className="how-it-works-text">
              <h3>{lang('Reassuring backup').heading}</h3>
              <p>{lang('Reassuring backup').content}</p>
            </div>
          </div>
          {sp !== 'onco' && (
            <div className="how-it-works-item">
              <img src={iconSupport} alt="support" />
              <div className="how-it-works-text">
                <h3>{lang('Friendly support').heading}</h3>
                <p>{lang('Friendly support').content}</p>
              </div>
            </div>
          )}
          <div className="how-it-works-item">
            <img src={iconSafe} alt="safe" />
            <div className="how-it-works-text">
              <h3>{lang('Safe & confidential').heading}</h3>
              <p>{lang('Safe & confidential').content}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="faqs-wrapper">
        <div className="faqs">
          <h3>{lang('Questions').heading}</h3>
          <p>{sp === 'onco' ? lang('Questions_onco').content : lang('Questions').content}</p>
          <CallToActionLink linkText={lang('Questions').cta} linkUrl={getFAQUrl()} />
        </div>
      </div>
    </main>
  )
}

export default WelcomeLandingPage
