export default {
  Welcome: {
    heading: `Welcome to the`,
  },
  'A simple system': {
    heading: `A simple system with a big heart`,
    content: `Now that you’ve enrolled through your pharmacy, get to know how the Aidia Smart Bottle and friendly support system works.`,
  },
  'A simple system_onco': {
    heading: `A simple system with a big heart`,
    content: `Now that you’ve enrolled through your pharmacy, get to know how the Aidia Smart Bottle works.`,
  },
  'Helpful reminders': {
    heading: `Helpful reminders`,
    content: `The Aidia Smart Bottle lights and chimes to gently alert you when it’s time for your next dose.`,
  },
  'Reassuring backup': {
    heading: `Reassuring backup`,
    content: `Personalized messages by text or call (your choice) to alert you if you are late or missed taking a dose.`,
  },
  'Friendly support': {
    heading: `Friendly support`,
    content: `Aidia Support is here to help along the way. They can change your bottle settings and update your dose schedule.`,
  },
  'Safe & confidential': {
    heading: `Safe & confidential`,
    content: `HIPAA compliant and secure so your private information will always remain private.`,
  },
  Questions: {
    heading: `Questions?`,
    content: `Check out these frequently asked questions.`,
    cta: `View FAQs`,
  },
  Questions_onco: {
    heading: `Questions?`,
    content: `Your pharmacy is here to help along the way. They can change your bottle settings and update your dose schedule. For help, contact Onco360 Oncology Pharmacy at (877) 662-6633 or check out these frequently asked questions.`,
    cta: `View FAQs`,
  },
}
