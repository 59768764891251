export default {
  Welcome: {
    heading: `Bienvenido al`,
  },
  'A simple system': {
    heading: `Un sistema simple con un gran corazón`,
    content: `Ahora que se ha inscrito a través de su farmacia, conozca cómo funciona el frasco inteligente Aidia y el sistema de soporte.`,
  },
  'A simple system_onco': {
    heading: `Un sistema simple con un gran corazón`,
    content: `Ahora que se ha inscrito a través de su farmacia, conozca cómo funciona el frasco inteligente Aidia.`,
  },
  'Helpful reminders': {
    heading: `Recordatorios útiles`,
    content: `El frasco inteligente Aidia se enciende y suena para alertarle levemente cuando es hora de su próxima dosis.`,
  },
  'Reassuring backup': {
    heading: `Respaldo seguro`,
    content: `Mensajes personalizados por mensaje de texto o llamada (a su elección) para avisarle si se ha atrasado o se le ha olvidado tomar una dosis.`,
  },
  'Friendly support': {
    heading: `Soporte`,
    content: `El soporte de Aidia está aquí para ayudarle. Puede cambiar la configuración de su frasco y actualizar los horarios de su dosis.`,
  },
  'Safe & confidential': {
    heading: `Seguro y confidencial`,
    content: `Cumple con HIPAA y es seguro, por lo que su información privada siempre permanecerá privada.`,
  },
  Questions: {
    heading: `¿Preguntas?`,
    content: `Consulte estas preguntas frecuentes.`,
    cta: `Ver preguntas frecuentes`,
  },
  Questions_onco: {
    heading: `¿Preguntas?`,
    content: `Su farmacia está aquí para ayudar. Pueden actualizar sus tiempos de dosis, pausar recordatorios y personalizar la configuración de su frasco o teléfono. Simplemente llame a la farmacia Onco360 Oncology al (877) 662-6633.`,
    cta: `Ver preguntas frecuentes`,
  },
}
